
/**
 * @name: 数据看板-数据看板
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 数据看板-数据看板
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import {productLossRankingApi, productProfitRankingApi, productSalesRankingApi} from "@/apis/kanban/data";

@Component({})
export default class kanbanData extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = {}
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10,
    endTime: '',
    startTime: '',
    saleTypes: 0,
    gateIdList: ''
  }
  // 表单参数
  salesRankingList: any[] = [];
  salesRankingListTop: any[] = [];
  lossRankingList: any[] = [];
  lossRankingListTop: any[] = [];
  profitRankingList: any[] = [];

  dateTime: any = this.getPreAndNextTime('06:00:00', '05:59:59');
  dateTimeLossRanking: any = this.getPreAndNextTime('06:00:00', '05:59:59');
  dateTimeProfitRanking: any = this.getPreAndNextTime('06:00:00', '05:59:59');
  checkinSum: string = ''
  /**
   * 获取商品销售排行
   */
  async getSalesRanking (){
    if (this.dateTime && this.dateTime.length > 0) {
      this.queryParam.startTime = this.dateTime[0];
      this.queryParam.endTime = this.dateTime[1];
    } else {
      this.queryParam.startTime = null;
      this.queryParam.endTime = null;
    }
    this.salesRankingList=[];
    this.salesRankingListTop=[];
    await productSalesRankingApi(this.queryParam).then(res => {
      res.map((item, index) => {
        if (index < 10) {
          this.salesRankingList.push(item)
        } else if (index >= 10 && index <= 20) {
          this.salesRankingListTop.push(item)
        }
      })
    })
  }

  /**
   * 获取商品损耗排行
   */
  async getLossRanking() {
    if (this.dateTimeLossRanking && this.dateTimeLossRanking.length > 0) {
      this.queryParam.startTime = this.dateTimeLossRanking[0];
      this.queryParam.endTime = this.dateTimeLossRanking[1];
    } else {
      this.queryParam.startTime = null;
      this.queryParam.endTime = null;
    }
    this.lossRankingList=[];
    this.lossRankingListTop=[];
    await productLossRankingApi({
      endTime: this.queryParam.endTime,
      startTime: this.queryParam.startTime,
    }).then(res => {
      res.map((item, index) => {
        if (index < 10) {
          this.lossRankingList.push(item)
        } else if (index >= 10 && index <= 20) {
          this.lossRankingListTop.push(item)
        }
      })
    })
  }

  /**
   * 获取商品利润排行
   */
  async getProfitRankingList () {
    if (this.dateTimeProfitRanking && this.dateTimeProfitRanking.length > 0) {
      this.queryParam.startTime = this.dateTimeProfitRanking[0];
      this.queryParam.endTime = this.dateTimeProfitRanking[1];
    } else {
      this.queryParam.startTime = null;
      this.queryParam.endTime = null;
    }
    this.profitRankingList=[];
    await productProfitRankingApi({
      endTime: this.queryParam.endTime,
      startTime: this.queryParam.startTime,
    }).then(res => {
      res.map((item, index) => {
        if (index <= 20) {
          this.profitRankingList.push(item)
        }
      })
    })
  }

  getPreAndNextTime(startTime: string, endTime: string): string[] {
    const now = new Date()
    const pre = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000))
    return [
      `${pre.getFullYear()}-${(pre.getMonth() + 1).toString().padStart(2, '0')}-${pre.getDate().toString().padStart(2, '0')} ${startTime}`,
      `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${endTime}`,
    ]
  }

  /**
   * 时间选择
   */
  async changeDateTime(types: number) {
    if (types == 1) {
      await this.getSalesRanking()
    } else if (types == 2) {
      await this.getLossRanking()
    } else {
      await this.getProfitRankingList()
    }
  }


// 初始化表格数据
  async initTableData() {
    await this.getSalesRanking();
    await this.getLossRanking()
    await this.getProfitRankingList()
  };

  created() {
    this.initTableData();
  }
}
