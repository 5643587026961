/**
 * @name: 数据看板-数据看板接口文件
 * @author:lili
 * @date: 2023-08-04 17:46
 * @description： 数据看板-数据看板接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IProductLossRanking, IProductProfitRanking, IProductSalesRanking} from "@/apis/kanban/data/types";

/**
 * 商品销售排行
 * @param params 查询参数
 * @returns
 */
export const productSalesRankingApi = (params: any) => get<IProductSalesRanking[]>("/admin/home/dataBoard/productSalesRanking", params)

/**
 * 商品利润排行
 * @param params 查询参数
 * @returns
 */
export const productProfitRankingApi = (params: any) => get<IProductProfitRanking[]>("/admin/home/dataBoard/productProfitRanking", params)

/**
 * 商品损耗排行
 * @param params 查询参数
 * @returns
 */
export const productLossRankingApi = (params: any) => get<IProductLossRanking[]>("/admin/home/dataBoard/productLossRanking", params)
